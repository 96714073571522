/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Roots = {
  // All pages
  common: {
    init: function() {
      //============================== ANIMATE GLOBAL ===============================//

      function animateIn(){
          $('.animate-identifier').each(function(){
              var eTop = $(this).offset().top;
              var distanceFromTop = eTop - $(window).scrollTop();
              if(distanceFromTop < ($(window).height()/1.5)){
                  $(this).find('.animate').addClass('active');
                  var element = $(this);
              }
          });
      }

      $(window).scroll(function() { //when window is scrolled
          animateIn();
      });

      $(window).load(function(){
          animateIn();
      });

      $('.testimonial-slider').slick({
          arrows:false,
          infinite: true,
          autoplay: true,
          dots: true,
          autoplaySpeed: 12000
      });
    }
  },
  // Home page
  templates_2_home: {
    init: function() {

    }
  },
  // questions page
  templates_2_questions: {
    init: function() {
      // JavaScript to be fired on the questions template

      setupQuestions();

      $(window).load(function(){
        //reveal cardlsider once page loaded
        $('.section-questions').addClass('show-questions');
      })

      $(function() {
          $('.question-content').matchHeight();
      });



    }
  },
  // results page
  templates_2_results: {
    init: function() {
      // JavaScript to be fired on the results template

      setupResults();

      $(function() {
          $('.individual-score').matchHeight();
      });

    }
  },
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Roots;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
