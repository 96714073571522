function setupQuestions(){
	processQuestions();
	sectionResize();
}

$(document).ready(function(){

	//shortcut to answer all questions quickly for testing
	$('.answer-all').click(function(){
		$('.radio-btn input').attr("checked", "checked");
	});
});



	function processQuestions() {
		//Count number of question pages and update pagination

		var questionsPages = $('.questions-set').length;
		$('.total-page').text(questionsPages);
		console.log(questionsPages);

		var updatePagination = function() {
			var activePage = $('.questions-set.active').attr('data-q-set')
			$('.current-page').text(activePage);

			//Hide submit button until last page
			if (activePage == questionsPages) {
				$('.btn-primary.finish').show();
			} else {
				$('.btn-primary.finish').hide();
			}

			// Hide back button on first page
			if (activePage == 1) {
				$('.back-arrow').hide();
			} else {
				$('.back-arrow').show();
			}

			console.log('pag updated');
		}

		updatePagination();


		var answers = [];
		$('.next').click(function(){
					var setCount = $('.questions-set.active .question').length;
					var checkCount = $('.questions-set.active .radio-btn input:checked').length;

					if( setCount !== checkCount ){
						alert('Please answer all questions');
						return false;
					}
					$('.questions-set.active .radio-btn input:checked').each(function (){
						var data = {
							"id":$(this).attr('data-id'),
							"answer":$(this).val()
						}
						answers.push(data);
					});

					var nextQuestionSet = $('.questions-set.active').attr('data-q-set') * 1 + 1;
					$('.questions-set.active').hide().removeClass('active');
					$('[data-q-set="' + nextQuestionSet + '"]').show().addClass('active');

					updatePagination();

					$("html, body").animate({ scrollTop: 0 }, "fast");

				 console.log(answers);
		});

		$('.back-arrow').click(function(){
			var prevQuestionSet = $('.questions-set.active').attr('data-q-set') * 1 - 1;
			$('.questions-set.active').hide().removeClass('active');
			$('[data-q-set="' + prevQuestionSet + '"]').show().addClass('active');
			answers.splice(Math.max(answers.length - 10));
			updatePagination();
		});

		$('.finish').click(function(){
				var setCount = $('.questions-set.active .question').length;
				var checkCount = $('.questions-set.active .radio-btn input:checked').length;

				if( setCount !== checkCount ){
					alert('Please answer all questions');
					return false;
				}
				$('.questions-set.active .radio-btn input:checked').each(function (){
					var data = {
						"id":$(this).attr('data-id'),
						"answer":$(this).val()
					}
					answers.push(data);
				});

				// console.log(answers);

				var str = JSON.stringify(answers);
				$("#answers").val(str);
				$("#answers").parents("form").submit();
		});
	}






	function sectionResize() {

		function resize() {
			var windowHeight 	= $(window).height();


			var heightToSet = windowHeight;


			$('.section-questions').height(heightToSet);

		}

		//resize();

		$(window).resize(function () {
			resize();
		});

	}
